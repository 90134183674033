import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background-color: ${(props) =>
    props.type === 'primary'
      ? '#ffc107'
      : props.type === 'socialButton'
      ? 'rgba(0,0,0,0.04)'
      : 'transparent'};
  height: ${(props) =>
    props.size === 'medium'
      ? '37px'
      : props.size === 'small'
      ? '37px'
      : props.size === 'large'
      ? '48px'
      : '37px'};
  width: 100%;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.4s;

  &:hover {
    background: ${(props) =>
      props.type === 'primary'
        ? `${darken(0.05, '#ffc107')}`
        : props.type === 'socialButton'
        ? `${darken(0.03, '#ccc')}`
        : `${darken(0.05, '#ffc107')}`};
  }

  img {
    margin-right: 15px;
  }
`;

export const WrapperDescription = styled.div``;

export const Description = styled.div`
  font-family: 'Roboto';
  font-size: ${(props) => (props.type === 'socialButton' ? '14px' : '16px')};
  font-weight: ${(props) => (props.type === 'socialButton' ? 500 : 'bold')};
`;

export const WrapperIconLeft = styled.div``;

export const WrapperIconRight = styled.div``;
