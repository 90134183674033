/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { string, func, element, bool } from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CustomizedDialog({
  opened,
  title,
  children,
  onClose,
  disabled,
  handleSave,
  maxWidth,
}) {
  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={opened}
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={onClose} color="primary">
            Cancelar
          </Button>
          <Button
            autoFocus
            onClick={handleSave}
            disabled={disabled}
            color="primary"
          >
            Salvar
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

CustomizedDialog.propTypes = {
  opened: bool,
  title: string,
  children: element.isRequired,
  onClose: func,
  disabled: bool,
  handleSave: func,
  maxWidth: string,
};

CustomizedDialog.defaultProps = {
  opened: false,
  title: '',
  onClose: () => {},
  disabled: bool,
  handleSave: () => {},
  maxWidth: 'md',
};

export default CustomizedDialog;
