import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  height: 100%;
  background: #F5F5F5;
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

export const Content = styled.div`
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 315px;
  min-height: 315px;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  padding: 20px;

  img {
    width: 143px;
    height: 72px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 80%;

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      margin: 0 0 10px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    span {
      color: #ff7979;
      align-self: flex-start;
      margin: -5px 0px 10px 0px;
      font-size: 11px;
      font-weight: 600;
    }

    button {
      margin: 5px 0 0;
      height: 44px;
      border: 0;
      border-radius: 4px;
      background: #9d0911;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      transition: background 0.4s;

      &:hover {
        background: ${darken(0.07, '#9D0911')};
      }
    }

    a {
      color: #989696;
      font-size: 12px;
      margin: 5px 0 0;
      transition: color 0.4s;

      &:hover {
        color: ${darken(0.07, '#989696')};
      }

      span {
        color: #989696;
        font-weight: bold;
      }
    }
  } */
`;
