import styled from 'styled-components';

export const Container = styled.div`
  height: 92.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
  }
`;
