import React from 'react';

import Button from '~/components/Button';
import { Images } from '~/assets/img';

import { Container, WrapperLeft, WrapperRight, Img } from './styles';

function Header() {
  return (
    <Container>
      <WrapperLeft>
        <Img src={Images.Logo} alt="logo" />
      </WrapperLeft>
      <WrapperRight>
        <Button
          type="primary"
          description="Baixar Floox app"
          onClick={() => {}}
        />
      </WrapperRight>
    </Container>
  );
}

export default Header;
