import React from 'react';

import { Images } from '~/assets/img';

import { Container } from './styles';

function Dashboard() {
  return (
    <Container>
      <img src={Images.ImgEmptyData} alt="Sem dados em tela" />
      <p>
        Seu espaço de trabalho está vazio. <br /> Comece selecionando um
        documento!
      </p>
    </Container>
  );
}

export default Dashboard;
