import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  CustomAccordion,
  CustomAccordionSummary,
  CustomAccordionDetails,
} from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderColor: 'red',
    borderWidth: 1,
    marginBottom: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#000000 !important',
    paddingLeft: '0px !important',
  },
}));

function Accordion({ title, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CustomAccordion>
        <CustomAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>{children}</CustomAccordionDetails>
      </CustomAccordion>
    </div>
  );
}

export default Accordion;
