export function registerRequest(data) {
  return {
    type: '@register/REGISTER_REQUEST',
    payload: data,
  };
}

export function registerSuccess(data) {
  return {
    type: '@register/REGISTER_SUCCESS',
    payload: { data },
  };
}

export function registerFailure() {
  return {
    type: '@register/REGISTER_FAILURE',
  };
}

export function getRegisterRequest() {
  return {
    type: '@register/GET_REGISTER_REQUEST',
  };
}

export function getRegisterSuccess(data) {
  return {
    type: '@register/GET_REGISTER_SUCCESS',
    payload: { data },
  };
}

export function getRegisterFailure() {
  return {
    type: '@register/GET_REGISTER_FAILURE',
  };
}

export function deleteRegisterRequest(id) {
  return {
    type: '@register/DELETE_REGISTER_REQUEST',
    payload: { id },
  };
}

export function deleteRegisterSuccess() {
  return {
    type: '@register/DELETE_REGISTER_SUCCESS',
  };
}

export function deleteRegisterFailure(error) {
  return {
    type: '@register/DELETE_REGISTER_FAILURE',
    payload: error,
  };
}

export function updateRegisterRequest(data) {
  return {
    type: '@register/UPDATE_REGISTER_REQUEST',
    payload: data,
  };
}

export function updateRegisterSuccess(data) {
  return {
    type: '@register/UPDATE_REGISTER_SUCCESS',
    payload: { data },
  };
}

export function updateRegisterFailure() {
  return {
    type: '@register/UPDATE_REGISTER_FAILURE',
  };
}
