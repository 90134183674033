import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import TextField from '~/components/TextField';

import CardHiring from './components/CardHiring';
import Dialog from '~/components/Dialog';
import Accordion from '~/components/Accordion';

import {
  Container,
  WrapperLeft,
  Header,
  WrapperCard,
  Footer,
  WrapperCenter,
  WrapperRight,
  WrapperHeader,
  WrapperData,
  Name,
  Date,
  WrapperIcon,
  WrapperBody,
  WrapperForm,
  WrapperCarousel,
  ImgCarousel,
  WrapperHorizontal,
  Spacing,
} from './styles';

import { Images } from '~/assets/img';

const mockData = [
  {
    id: 1,
    date: '10/10/2020',
    uhn: '908800',
    numberCompanions: '4',
    entrydate: '04/06/2020',
    departuredate: '04/06/2020',
    checkintime: '14:36',
    checkouttime: '14:36',
    email: 'luiz@teste.com',
    nationality: 'Brasileiro',
    cpf: '122.222.333-22',
    name: 'Luiz Fernando Fonseca',
    birthdate: '20/09/1988',
    age: '32',
    ocupation: 'Engenheiro',
    gender: 'Masculino',
    document: '12345676',
    documenttype: 'RG',
    emmiterorgan: 'IFP',
    zipcode: '23914-305',
    addressnumber: '123',
    address: 'Rua Guilherme A',
    city: 'Angra dos Reis',
    state: 'RJ',
    country: 'Brasil',
    phone: '(21) 99951-0066',
    lastdestiny: 'Angra dos Reis',
    nextdestiny: 'Curitiba',
    img: [
      {
        label: 'Doc 1',
        imgPath: `${Images.ImgTest1}`,
      },
      {
        label: 'Doc 2',
        imgPath: `${Images.ImgTest2}`,
      },
      {
        label: 'Doc 3',
        imgPath: `${Images.ImgTest1}`,
      },
      {
        label: 'Doc 4',
        imgPath: `${Images.ImgTest2}`,
      },
    ],
  },
  {
    id: 2,
    date: '01/08/2020',
    uhn: '414300',
    numberCompanions: '4',
    entrydate: '05/06/2020',
    departuredate: '05/07/2020',
    checkintime: '11:36',
    checkouttime: '12:36',
    email: 'marcelo@teste.com',
    nationality: 'Brasileiro',
    cpf: '122.222.333-22',
    name: 'Marcelo Silva',
    birthdate: '20/09/1978',
    age: '42',
    ocupation: 'Engenheiro',
    gender: 'Masculino',
    document: '12345676',
    documenttype: 'RG',
    emmiterorgan: 'IFP',
    zipcode: '23914-305',
    addressnumber: '123',
    address: 'Rua Teste A',
    city: 'Curitiba',
    state: 'PR',
    country: 'Brasil',
    phone: '(41) 99944-0001',
    lastdestiny: 'Curitiba',
    nextdestiny: 'Angra dos Reis',
    img: [
      {
        label: 'Doc 1',
        imgPath: `${Images.ImgTest2}`,
      },
      {
        label: 'Doc 2',
        imgPath: `${Images.ImgTest1}`,
      },
    ],
  },
];

export default function Dashboard() {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [itemActive, setItemActive] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container>
      <WrapperLeft>
        <Header>
          <p>Check-in</p>
        </Header>
        <WrapperCard>
          {mockData.map((item) => (
            <CardHiring
              name={item.name}
              date={item.date}
              active={item.id === itemActive}
              onClick={() => {
                setItemActive(item.id);
                setItemSelected(item);
                setMaxSteps(item.img.length);
                setActiveStep(0);
              }}
            />
          ))}
        </WrapperCard>
        <Footer>
          <p>FAZER DOWNLOAD</p>
          <img src={Images.IconDownload} alt="Icone para download da imagem" />
        </Footer>
      </WrapperLeft>
      <WrapperCenter>
        {itemSelected !== null && (
          <>
            <WrapperHeader>
              <WrapperData>
                <Name>{itemSelected.name}</Name>
                <Date>{itemSelected.date}</Date>
              </WrapperData>
              <WrapperIcon>
                <img
                  src={Images.IconDownload}
                  alt="Icone para download da imagem"
                />
              </WrapperIcon>
            </WrapperHeader>
            <WrapperBody>
              {itemSelected.img.map((item, index) => (
                <img
                  src={item.imgPath}
                  alt={item.label}
                  onClick={() => {
                    setActiveStep(index);
                    setOpenModal(true)}
                  }
                />
              ))}
            </WrapperBody>
          </>
        )}
      </WrapperCenter>
      <WrapperRight>
        <p>DETALHES</p>
        {itemSelected !== null && (
          <>
            <WrapperForm>

            <Accordion title="Informações da Hospedagem">
              <TextField
                variant="filled"
                color="primary"
                label="UH N° (Local)"
                name="uhn"
                placeholder="Informe UH N° (Local)"
                value={itemSelected.uhn}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="N° Acompanhantes"
                name="numberCompanions"
                placeholder="Informe N° Acompanhantes"
                value={itemSelected.numberCompanions}
                fullWidth
              />
              <WrapperHorizontal>
              <TextField
                variant="filled"
                color="primary"
                label="Entrada"
                name="entrydate"
                placeholder="Informe Data de Entrada"
                value={itemSelected.entrydate}
                fullWidth
              />
              <Spacing />
              <TextField
                variant="filled"
                color="primary"
                label="Hora"
                name="checkintime"
                placeholder="Informe Hora de Entrada"
                value={itemSelected.checkintime}
                fullWidth
              />
              </WrapperHorizontal>
              <WrapperHorizontal>
              <TextField
                variant="filled"
                color="primary"
                label="Saída"
                name="departuredate"
                placeholder="Informe Data de Saída"
                value={itemSelected.departuredate}
                fullWidth
              />
              <Spacing />
              <TextField
                variant="filled"
                color="primary"
                label="Hora"
                name="checkouttime"
                placeholder="Informe Hora de Saída"
                value={itemSelected.checkouttime}
                fullWidth
              />
              </WrapperHorizontal>
            </Accordion>

            <Accordion title="Informações do Hospede">
              <TextField
                variant="filled"
                color="primary"
                label="Email"
                name="email"
                placeholder="Informe Email"
                value={itemSelected.email}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Nacionalidade"
                name="nationality"
                placeholder="Informe nacionalidade"
                value={itemSelected.nationality}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="CPF"
                name="cpf"
                placeholder="Informe CPF"
                value={itemSelected.cpf}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Nome"
                name="name"
                placeholder="Informe nome"
                value={itemSelected.name}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Data de Nascimento"
                name="birthdate"
                placeholder="Informe data de nascimento"
                value={itemSelected.birthdate}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Idade"
                name="age"
                placeholder="Informe Idade"
                value={itemSelected.age}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Profissão"
                name="ocupation"
                placeholder="Informe Profissão"
                value={itemSelected.ocupation}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Sexo"
                name="gender"
                placeholder="Informe Sexo"
                value={itemSelected.gender}
                fullWidth
              />
            </Accordion>

            <Accordion title="Documento de Identidade">
              <TextField
                variant="filled"
                color="primary"
                label="Número"
                name="document"
                placeholder="Informe número documento"
                value={itemSelected.document}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Tipo"
                name="documenttype"
                placeholder="Informe Tipo de documento"
                value={itemSelected.documenttype}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Órgão Expedidor"
                name="emmiterorgan"
                placeholder="Informe Órgão Expedidor"
                value={itemSelected.emmiterorgan}
                fullWidth
              />
            </Accordion>

            <Accordion title="Endereço">
              <TextField
                variant="filled"
                color="primary"
                label="CEP"
                name="zipcode"
                placeholder="Informe CEP"
                value={itemSelected.zipcode}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Endereço"
                name="address"
                placeholder="Informe Endereço"
                value={itemSelected.address}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Número"
                name="addressnumber"
                placeholder="Informe Número"
                value={itemSelected.addressnumber}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Cidade"
                name="city"
                placeholder="Informe Cidade"
                value={itemSelected.city}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Estado"
                name="state"
                placeholder="Informe Estado"
                value={itemSelected.state}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="País"
                name="country"
                placeholder="Informe País"
                value={itemSelected.country}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Telefone"
                name="phone"
                placeholder="Informe seu telefone"
                value={itemSelected.phone}
                fullWidth
              />
            </Accordion>

            <Accordion title="Outros">
            </Accordion>

            </WrapperForm>
          </>
        )}
      </WrapperRight>
      <Dialog
        opened={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="sm"
      >
        <WrapperCarousel>
          <ImgCarousel
            src={itemSelected?.img[activeStep]?.imgPath}
            alt={itemSelected?.img[activeStep]?.label}
          />
          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                PRÓXIMA
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                VOLTAR
              </Button>
            }
          />
        </WrapperCarousel>
      </Dialog>
    </Container>
  );
}
