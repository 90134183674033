import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

export const Container = styled.div``;

export const CustomAccordion = withStyles({
  root: (props) => ({
    backgroundColor: '#EBEBEB',
    color: 'red',
  }),
})(Accordion);

export const CustomAccordionSummary = withStyles({
  root: (props) => ({
    '&:hover': {
      // backgroundColor: '#3D84A830',
    },
  }),
})(AccordionSummary);

export const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: red;
`;

export const CustomAccordionDetails = withStyles({
  root: (props) => ({
    display: 'block',
    fill: '#9D9D9D',
    transition: '100ms',
    '&:hover': {
      fill: '#3D84A8',
    },
  }),
})(AccordionDetails);
