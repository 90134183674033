import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Container } from './styles';

function LoadingIndicator() {
  return (
    <Container>
      <CircularProgress />;
    </Container>
  );
}

export default LoadingIndicator;
