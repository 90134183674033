import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import TextField from '~/components/TextField';

import CardHiring from './components/CardHiring';
import Dialog from '~/components/Dialog';

import {
  Container,
  WrapperLeft,
  Header,
  WrapperCard,
  Footer,
  WrapperCenter,
  WrapperRight,
  WrapperHeader,
  WrapperData,
  Name,
  Date,
  WrapperIcon,
  WrapperBody,
  WrapperForm,
  WrapperCarousel,
  ImgCarousel,
} from './styles';

import { Images } from '~/assets/img';

const mockData = [
  {
    id: 1,
    name: 'Bianca Guimarães',
    date: '30/07/2020',
    birthdate: '20/09/1989',
    phone: '(41) 99876-5432',
    email: 'teste@test.com',
    rg: '12345676',
    pis: '12345',
    img: [
      {
        label: 'Doc 1',
        imgPath: `${Images.ImgTest1}`,
      },
      {
        label: 'Doc 2',
        imgPath: `${Images.ImgTest2}`,
      },
      {
        label: 'Doc 3',
        imgPath: `${Images.ImgTest1}`,
      },
      {
        label: 'Doc 4',
        imgPath: `${Images.ImgTest2}`,
      },
      {
        label: 'Doc 5',
        imgPath: `${Images.ImgTest1}`,
      },
      {
        label: 'Doc 6',
        imgPath: `${Images.ImgTest2}`,
      },
      {
        label: 'Doc 7',
        imgPath: `${Images.ImgTest1}`,
      },
      {
        label: 'Doc 8',
        imgPath: `${Images.ImgTest2}`,
      },
    ],
  },
  {
    id: 2,
    name: 'Roberto Moreira',
    date: '28/07/2020',
    img: [
      {
        label: 'Doc 1',
        imgPath: `${Images.ImgTest1}`,
      },
    ],
    birthdate: '13/09/1949',
    email: 'teste@test.com',
    phone: '(21) 99222-5432',
    rg: '43234779',
    pis: '54231',
  },
  {
    id: 3,
    name: 'Luiz Fernando Fonseca',
    date: '30/07/2020',
    birthdate: '20/09/1989',
    phone: '(21) 99951-0066',
    email: 'luiz@teste.com',
    rg: '12345676',
    pis: '12345',
    img: [
      {
        label: 'Doc 1',
        imgPath: `${Images.ImgTest1}`,
      },
      {
        label: 'Doc 2',
        imgPath: `${Images.ImgTest2}`,
      },
      {
        label: 'Doc 3',
        imgPath: `${Images.ImgTest1}`,
      },
      {
        label: 'Doc 4',
        imgPath: `${Images.ImgTest2}`,
      },
    ],
  },
];

export default function Dashboard() {
  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);
  const [itemActive, setItemActive] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container>
      <WrapperLeft>
        <Header>
          <p>Contratações</p>
        </Header>
        <WrapperCard>
          {mockData.map((item) => (
            <CardHiring
              name={item.name}
              date={item.date}
              active={item.id === itemActive}
              onClick={() => {
                setItemActive(item.id);
                setItemSelected(item);
                setMaxSteps(item.img.length);
                setActiveStep(0);
              }}
            />
          ))}
        </WrapperCard>
        <Footer>
          <p>FAZER DOWNLOAD</p>
          <img src={Images.IconDownload} alt="Icone para download da imagem" />
        </Footer>
      </WrapperLeft>
      <WrapperCenter>
        {itemSelected !== null && (
          <>
            <WrapperHeader>
              <WrapperData>
                <Name>{itemSelected.name}</Name>
                <Date>{itemSelected.date}</Date>
              </WrapperData>
              <WrapperIcon>
                <img
                  src={Images.IconDownload}
                  alt="Icone para download da imagem"
                />
              </WrapperIcon>
            </WrapperHeader>
            <WrapperBody>
              {itemSelected.img.map((item, index) => (
                <img
                  src={item.imgPath}
                  alt={item.label}
                  onClick={() => {
                    setActiveStep(index);
                    setOpenModal(true)}
                  }
                />
              ))}
            </WrapperBody>
          </>
        )}
      </WrapperCenter>
      <WrapperRight>
        <p>DETALHES</p>
        {itemSelected !== null && (
          <>
            <WrapperForm>
              <TextField
                variant="filled"
                color="primary"
                label="Nome"
                name="name"
                placeholder="Informe e-mail"
                value={itemSelected.name}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Data de Nascimento"
                name="birthdate"
                placeholder="Informe data de nascimento"
                value={itemSelected.birthdate}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="Email"
                name="email"
                placeholder="Informe seu telefone"
                value={itemSelected.phone}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="RG"
                name="rg"
                placeholder="Informe seu RG"
                value={itemSelected.rg}
                fullWidth
              />
              <TextField
                variant="filled"
                color="primary"
                label="PIS"
                name="pis"
                placeholder="Informe seu PIS"
                value={itemSelected.pis}
                fullWidth
              />
            </WrapperForm>
          </>
        )}
      </WrapperRight>
      <Dialog
        opened={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="sm"
      >
        <WrapperCarousel>
          <ImgCarousel
            src={itemSelected?.img[activeStep]?.imgPath}
            alt={itemSelected?.img[activeStep]?.label}
          />
          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                PRÓXIMA
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                VOLTAR
              </Button>
            }
          />
        </WrapperCarousel>
      </Dialog>
    </Container>
  );
}
