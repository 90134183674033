import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import Button from '~/components/Button';
import TextField from '~/components/TextField';
import Dialog from '~/components/Dialog';

import { Images } from '~/assets/img';

import {
  Container,
  Rectangle,
  Content,
  Wrapper,
  WrapperTop,
  WrapperBottom,
  WrapperButtons,
  WrapperForm,
  WrapperForgotPwd,
  ForgotPwd,
  WrapperMessage,
  Title,
  Subtitle,
  WrapperInput,
} from './styles';

import { schema, forgotschema } from './schemaValidation';

import { signInRequest } from '~/store/modules/auth/actions';

export default function SignIn() {
  const dispatch = useDispatch();
  const [emailAccess, setEmailAccess] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const renderButtons = () => {
    return (
      <WrapperButtons>
        <Button
          type="socialButton"
          description="CONTINUAR COM GOOGLE"
          onClick={() => {}}
          iconLeft={Images.IconGoogle}
          size="large"
        />
        <Button
          type="socialButton"
          description="CONTINUAR COM FACEBOOK"
          onClick={() => {}}
          iconLeft={Images.IconFacebook}
          size="large"
        />
        <Button
          type="socialButton"
          description="CONTINUAR COM LINKEDIN"
          onClick={() => {}}
          iconLeft={Images.IconLinkedin}
          size="large"
        />
        <img src={Images.IconBarOr} alt="Icone barra ou" />
        <Button
          type="primary"
          description="ACESSAR COM EMAIL"
          onClick={() => setEmailAccess(true)}
          size="large"
        />
      </WrapperButtons>
    );
  };

  const handleSubmit = (values) => {
    dispatch(signInRequest(values.email, values.password));
  };

  return (
    <Container>
      <>
        <Rectangle />
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={schema}
        >
          {({ values, handleChange, errors, touched, handleSubmit }) => (
            <>
              <Wrapper>
                <Content>
                  <WrapperTop>
                    <img src={Images.ImgWelcome} alt="Imagem Bem-vindo" />
                    <p>Para continuar, inicie a sessão com sua conta</p>
                  </WrapperTop>
                  <WrapperBottom>
                    {!emailAccess ? (
                      renderButtons()
                    ) : (
                      <WrapperForm>
                        <TextField
                          variant="filled"
                          color="primary"
                          label="E-mail"
                          name="email"
                          placeholder="Informe e-mail"
                          helperText={touched.email ? errors.email : ''}
                          error={Boolean(errors.email)}
                          value={values.email}
                          onChange={handleChange}
                          fullWidth
                        />
                        <TextField
                          variant="filled"
                          color="primary"
                          label="Senha"
                          name="password"
                          type="password"
                          placeholder="Informe uma senha"
                          helperText={touched.password ? errors.password : ''}
                          error={Boolean(errors.password)}
                          value={values.password}
                          onChange={handleChange}
                          fullWidth
                        />

                        <WrapperForgotPwd>
                          <ForgotPwd>
                            Esqueceu sua senha?{' '}
                            <span onClick={() => setOpenModal(true)}>
                              Clique aqui
                            </span>
                          </ForgotPwd>
                        </WrapperForgotPwd>

                        <Button
                          type="primary"
                          description="ENTRAR"
                          onClick={handleSubmit}
                          size="large"
                        />
                      </WrapperForm>
                    )}
                  </WrapperBottom>
                </Content>
              </Wrapper>
            </>
          )}
        </Formik>
        <Formik
          enableReinitialize
          initialValues={{
            forgot_email: '',
          }}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={forgotschema}
        >
          {({ values, handleChange, errors, touched, handleSubmit }) => (
            <>
              <Dialog
                opened={openModal}
                onClose={() => setOpenModal(false)}
                maxWidth="sm"
              >
                <WrapperMessage>
                  <Title>Recuperar senha</Title>
                  <Subtitle>
                    Informe seu email para recuperar sua senha.
                  </Subtitle>
                </WrapperMessage>
                <WrapperInput>
                  <TextField
                    variant="filled"
                    color="primary"
                    label="E-mail"
                    name="forgot_email"
                    placeholder="Informe e-mail"
                    helperText={touched.forgot_email ? errors.forgot_email : ''}
                    error={Boolean(errors.forgot_email)}
                    value={values.forgot_email}
                    onChange={handleChange}
                    fullWidth
                  />
                </WrapperInput>
                <Button
                  type="primary"
                  description="CONTINUAR"
                  onClick={handleSubmit}
                  size="large"
                />
              </Dialog>
            </>
          )}
        </Formik>
      </>
    </Container>
  );
}
