import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  personId: null,
  data: [],
  error: null,
};

export default function registerUser(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@register/REGISTER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@register/REGISTER_SUCCESS': {
        draft.data = [...draft.data, action.payload.data];
        draft.loading = false;
        break;
      }
      case '@register/REGISTER_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@register/GET_REGISTER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@register/GET_REGISTER_SUCCESS': {
        draft.data = action.payload.data;
        draft.loading = false;
        break;
      }
      case '@register/GET_REGISTER_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@register/DELETE_REGISTER_REQUEST': {
        draft.loading = true;
        const productIndex = draft.data.findIndex(
          (register) => register.PersonId === action.payload.id
        );
        if (productIndex >= 0) {
          draft.data.splice(productIndex, 1);
        }
        break;
      }
      case '@register/DELETE_REGISTER_SUCCESS': {
        draft.loading = false;
        break;
      }
      case '@register/DELETE_REGISTER_FAILURE': {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
      case '@register/UPDATE_REGISTER_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@register/UPDATE_REGISTER_SUCCESS': {
        const productIndex = draft.data.findIndex(
          (register) => register.PersonId === action.payload.data.PersonId
        );
        if (productIndex >= 0) {
          draft.data[productIndex] = action.payload.data;
        }
        draft.loading = false;
        break;
      }
      case '@register/UPDATE_REGISTER_FAILURE': {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
