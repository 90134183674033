import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
`;

export const WrapperLeft = styled.div``;

export const WrapperRight = styled.div`
  width: 151px;
`;

export const Img = styled.img``;
