import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export const Wrapper = styled.div`
  height: 100%;
  background: #f0f0f0;
`;

export const WrapperWorkspace = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.div`
  width: 57px;
  height: 57px;
  background-color: #ffdb6e;
  border-radius: 30px;
`;

export const CustomList = withStyles({
  root: (props) => ({
    justifyContent: 'center',
  }),
})(List);

export const CustomListItem = withStyles({
  root: (props) => ({
    '&:hover': {
      backgroundColor: '#3D84A830',
    },
  }),
})(ListItem);

export const CustomListItemIcon = withStyles({
  root: (props) => ({
    fill: '#9D9D9D',
    transition: '100ms',
    '&:hover': {
      fill: '#3D84A8',
    },
  }),
})(ListItemIcon);
