import React from 'react';

import { Container, Name, Date } from './styles';

function CardHiring({ name, date, active, onClick }) {
  return (
    <Container active={active} onClick={onClick}>
      <Name>{name}</Name>
      <Date>{date}</Date>
    </Container>
  );
}

export default CardHiring;
