import React from 'react';
import { string, func, element, bool } from 'prop-types';
// import InputAdornment from '@material-ui/core/InputAdornment';

import { Container, TextInput } from './styles';

function CustomTextField({
  label,
  name,
  type,
  placeholder,
  helperText,
  error,
  value,
  onChange,
  fullWidth,
  icon,
  color,
  variant,
}) {
  return (
    <Container>
      <TextInput
        label={label}
        name={name}
        type={type}
        color={color}
        placeholder={placeholder}
        helperText={helperText}
        error={error}
        value={value}
        onChange={onChange}
        fullWidth={fullWidth}
        // InputProps={{
        //   startAdornment: (
        //     <InputAdornment position="start">{icon}</InputAdornment>
        //   ),
        // }}
        variant={variant}
      />
    </Container>
  );
}

CustomTextField.propTypes = {
  label: string,
  name: string,
  type: string,
  placeholder: string,
  helperText: string,
  error: string,
  value: string,
  onChange: func,
  fullWidth: bool,
  icon: element,
  color: string,
  variant: string,
};

CustomTextField.defaultProps = {
  label: '',
  name: '',
  type: 'text',
  placeholder: 'Preencha este campo',
  helperText: '',
  error: '',
  value: '',
  fullWidth: false,
  icon: '',
  onChange: () => {},
  color: 'primary',
  variant: 'filled',
};

export default CustomTextField;
