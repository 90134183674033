import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '~/services/history';
import api from '~/services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  const { email, password } = payload;

  try {
    // const response = yield call(api.post, 'person/authenticate', {
    //   email,
    //   password,
    // });

    // const { Token, User } = response.data;

    const Token = 'asas780123iuoj1nflsdkhilah';
    const User = 'Luiz Fernando Fonseca';

    // api.defaults.headers.Authorization = `Bearer ${Token}`;

    yield put(signInSuccess(Token, User));

    history.push('/hiring');
  } catch (error) {
    toast.error('Falha na autenticação');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
]);
