import React from 'react';

function IconSleppingBed() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="15"
      viewBox="0 0 22 15"
    >
      <path
        id="Path_1879"
        data-name="Path 1879"
        d="M7,13a3,3,0,1,0-3-3A3,3,0,0,0,7,13ZM19,7H11v7H3V5H1V20H3V17H21v3h2V11A4,4,0,0,0,19,7Z"
        transform="translate(-1 -5)"
      />
    </svg>
  );
}

export default IconSleppingBed;
