import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

export const Container = styled.div`
  margin-bottom: 15px;
`;

export const TextInput = withStyles({
  root: (props) => ({
    '& .MuiFormLabel-root': {
      color: props.error
        ? '#F44336'
        : props.color === 'primary'
        ? 'rgba(0,0,0,0.38)'
        : props.color === 'secondary'
        ? '#112850'
        : 'rgba(0,0,0,0.38)',
    },
    '& .MuiFilledInput-underline:before': {
      borderColor: props.error
        ? '#F44336'
        : props.color === 'primary'
        ? 'rgba(0,0,0,0.38)'
        : props.color === 'secondary'
        ? '#112850'
        : 'rgba(0,0,0,0.38)',
    },
    '& .MuiFilledInput-underline:after': {
      borderColor: props.error
        ? '#F44336'
        : props.color === 'primary'
        ? 'rgba(0,0,0,0.38)'
        : props.color === 'secondary'
        ? '#112850'
        : 'rgba(0,0,0,0.38)',
    },
  }),
})(TextField);
