import styled from 'styled-components';

export const Container = styled.div`
  border: 0px solid;
  padding: 15px 10px 15px 31px;
  background-color: ${(props) => (props.active ? '#F5F5F5' : '#FFF')};
  border-left-width: 5px;
  border-left-color: ${(props) => (props.active ? '#4F83A5' : 'transparent')};
  cursor: pointer;
`;

export const Name = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
`;

export const Date = styled.p``;
