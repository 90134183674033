import styled from 'styled-components';

export const Container = styled.div``;

export const Rectangle = styled.div`
  background-color: #3d84a8;
  height: 320px;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 121px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  background-color: #fff;
  width: 812px;
  height: 668px;
  border-radius: 10px;
`;

export const WrapperTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 30px;

  img {
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    font-family: 'Roboto';
  }
`;

export const WrapperBottom = styled.div`
  display: flex;
  justify-content: center;
`;

export const WrapperButtons = styled.div`
  width: 330px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const WrapperForm = styled.div`
  width: 330px;
  height: 300px;
  display: flex;
  flex-direction: column;
`;

export const WrapperForgotPwd = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

export const ForgotPwd = styled.p`
  font-size: 12px;
  font-family: 'Roboto';

  span {
    color: #3d84a8;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const WrapperMessage = styled.div`
  margin-bottom: 25px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-family: 'Roboto';
  font-weight: bold;
  color: #3d84a8;
  margin-bottom: 8px;
`;

export const Subtitle = styled.p`
  font-size: 16px;
  font-family: 'Roboto';
  color: rgba(0, 0, 0, 0.6);
`;

export const WrapperInput = styled.div`
  margin-bottom: 32px;
`;
