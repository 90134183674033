import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import Dashboard from '~/pages/Dashboard';
import Hiring from '~/pages/Hiring';
import CheckIn from '~/pages/Checkin';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/hiring" component={Hiring} isPrivate />
      <Route path="/checkin" component={CheckIn} isPrivate />
    </Switch>
  );
}
