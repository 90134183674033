import React from 'react';

const IconSupervised = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        id="Path_495"
        data-name="Path 495"
        d="M11.99,2a10,10,0,1,0,10,10A10,10,0,0,0,11.99,2ZM15.6,8.34a1.93,1.93,0,1,1-1.93,1.93A1.925,1.925,0,0,1,15.6,8.34Zm-6-1.58A2.36,2.36,0,1,1,7.24,9.12,2.364,2.364,0,0,1,9.6,6.76Zm0,9.13v3.75a8.007,8.007,0,0,1-5.14-4.96c1.05-1.12,3.67-1.69,5.14-1.69a10.172,10.172,0,0,1,1.9.22A3.022,3.022,0,0,0,9.6,15.89ZM11.99,20a6.851,6.851,0,0,1-.79-.04V15.89c0-1.42,2.94-2.13,4.4-2.13a7.089,7.089,0,0,1,3.84,1.15A8.019,8.019,0,0,1,11.99,20Z"
        transform="translate(-1.99 -2)"
      />
    </svg>
  );
};

export default IconSupervised;
