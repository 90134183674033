import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '~/services/history';
import api from '~/services/api';

import {
  registerSuccess,
  registerFailure,
  getRegisterSuccess,
  getRegisterFailure,
  deleteRegisterSuccess,
  deleteRegisterFailure,
  updateRegisterSuccess,
  updateRegisterFailure,
} from './actions';

export function* createUser({ payload }) {
  const { name, document, email, phone, mobilephone } = payload;

  try {
    const response = yield call(api.post, 'Person', {
      PersonName: name,
      PersonCode: document,
      Email: email,
      Password: '123456',
      Telephone: phone,
      Mobile: mobilephone,
    });

    yield put(registerSuccess(response.data));

    history.push('/register');
    toast.success('Usuário cadastrado com sucesso!');
  } catch (error) {
    toast.error('Falha ao realizar o cadastro');
    yield put(registerFailure());
  }
}

export function* getUsers() {
  try {
    const response = yield call(api.get, 'Person');
    yield put(getRegisterSuccess(response.data));
  } catch (error) {
    toast.error('Falha ao realizar a consulta dos dados');
    yield put(getRegisterFailure());
  }
}

export function* deleteUsers({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(api.delete, `Person/${id}`);
    toast.success('Registro apagado com sucesso!');
    yield put(deleteRegisterSuccess(response.data));
  } catch (error) {
    toast.error('Falha ao deletar um dado');
    yield put(deleteRegisterFailure(error.response.data.message));
  }
}

export function* updateUser({ payload }) {
  const { id, name, document, email, phone, mobilephone, password } = payload;

  try {
    const response = yield call(api.post, 'Person', {
      PersonId: id,
      PersonName: name,
      PersonCode: document,
      Email: email,
      Password: password,
      Telephone: phone,
      Mobile: mobilephone,
    });

    yield put(updateRegisterSuccess(response.data));

    history.push('/register');
    toast.success('Usuário alterado com sucesso!');
  } catch (error) {
    toast.error('Falha ao realizar a atualização');
    yield put(updateRegisterFailure());
  }
}

export default all([
  takeLatest('@register/REGISTER_REQUEST', createUser),
  takeLatest('@register/GET_REGISTER_REQUEST', getUsers),
  takeLatest('@register/DELETE_REGISTER_REQUEST', deleteUsers),
  takeLatest('@register/UPDATE_REGISTER_REQUEST', updateUser),
]);
