import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  padding-left: 15px;

  p {
    font-size: 26px;
    font-weight: bold;
  }
`;

export const WrapperCard = styled.div`
  flex: 1;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  cursor: pointer;

  p {
    font-size: 14px;
    font-weight: bold;
    margin-right: 15px;
  }
`;

export const WrapperLeft = styled.div`
  background-color: #ffffff;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 95vh;
`;

export const WrapperCenter = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex: 4;
  flex-direction: column;
`;

export const WrapperRight = styled.div`
  background-color: #fafafa;
  display: flex;
  flex: 1.5;
  flex-direction: column;
  padding-top: 32px;

  p {
    font-size: 14px;
    font-weight: bold;
    color: #3d84a8;
    padding-left: 20px;
  }
`;

export const WrapperForm = styled.div`
  padding: 20px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 60px;
`;

export const WrapperData = styled.div``;

export const Name = styled.p`
  font-size: 32px;
  font-weight: bold;
`;

export const Date = styled.p`
  font-size: 18px;
`;

export const WrapperIcon = styled.div`
  cursor: pointer;
`;

export const WrapperBody = styled.div`
  display: flex;
  padding-left: 60px;
  flex-wrap: wrap;

  img {
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 20px;
  }
`;

export const WrapperCarousel = styled.div``;

export const ImgCarousel = styled.img`
  overflow: hidden;
  display: block;
  width: 100%;
`;

export const WrapperHorizontal = styled.div`
  display: flex;
`;

export const Spacing = styled.div`
  padding: 0px 5px;
`;
