import React from 'react';
import { string, func, number, bool, objectOf, any } from 'prop-types';

import LoadingIndicator from '~/components/LoadingIndicator';

import {
  Container,
  WrapperDescription,
  Description,
  WrapperIconLeft,
  WrapperIconRight,
} from './styles';

const Button = ({
  type,
  description,
  style,
  onClick,
  loading,
  disabled,
  iconLeft,
  iconRight,
  size,
}) => (
  <Container
    type={type}
    style={style}
    onClick={onClick}
    disabled={disabled}
    size={size}
  >
    {loading ? (
      <LoadingIndicator />
    ) : (
      <>
        {iconLeft && (
          <>
            <WrapperIconLeft>
              <img src={iconLeft} alt="icon" />
            </WrapperIconLeft>
          </>
        )}
        <WrapperDescription>
          <Description type={type} size={size}>
            {description}
          </Description>
        </WrapperDescription>
        {iconRight && (
          <>
            <WrapperIconRight>
              <img src={iconRight} alt="icon" />
            </WrapperIconRight>
          </>
        )}
      </>
    )}
  </Container>
);

Button.propTypes = {
  type: string,
  style: string,
  description: string,
  onClick: func,
  loading: bool,
  disabled: bool,
  iconLeft: string,
  iconRight: string,
  size: string,
};

Button.defaultProps = {
  type: 'primary',
  style: null,
  description: 'Informe o texto do botao',
  loading: false,
  disabled: false,
  onClick: () => {},
  iconLeft: null,
  iconRight: null,
  size: 'medium',
};

export default Button;
