import Logo from './logo.png';
import LogoFlooxWeb from './logo-floox.png';
import ImgWelcome from './img-welcome.png';
import IconFacebook from './icon-facebook.png';
import IconGoogle from './icon-google.png';
import IconLinkedin from './icon-linkedin.png';
import IconBarOr from './icon-baror.png';
import IconSupervised from './icon-supervised';
import IconPlus from './icon-plus';
import IconSleppingBed from './icon-sleppingbed';
import IconDownload from './icon-download.png';
import ImgTest1 from './img-test1.png';
import ImgTest2 from './img-test2.png';
import ImgEmptyData from './img-emptydata.png';

export const Images = {
  Logo,
  LogoFlooxWeb,
  ImgWelcome,
  IconFacebook,
  IconGoogle,
  IconLinkedin,
  IconBarOr,
  IconSupervised,
  IconPlus,
  IconSleppingBed,
  IconDownload,
  ImgTest1,
  ImgTest2,
  ImgEmptyData,
};
