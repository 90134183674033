import * as yup from 'yup';

export const schema = yup.object().shape({
  email: yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
  password: yup.string().required('Senha é obrigatória'),
});

export const forgotschema = yup.object().shape({
  forgot_email: yup
    .string()
    .email('E-mail inválido')
    .required('E-mail é obrigatório'),
});
